export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents a date formated "Y-m-d\TH:i:sP". */
  DateTime: { input: string; output: string; }
  /** The `MonthYear` scalar type represents a date formated "MM/YYYY". */
  MonthYear: { input: string; output: string; }
  /** The `QuizNumber` scalar type represents a quiz number value. It should be an integer greater or equal to 1000 and lower than 10000. */
  QuizNumber: { input: number; output: number; }
  Upload: { input: object; output: object; }
};

export type AccountDeletionRequest = {
  __typename?: 'AccountDeletionRequest';
  id: Scalars['ID']['output'];
  user?: Maybe<User>;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AggregatedStatistic = {
  __typename?: 'AggregatedStatistic';
  averageQuizDone: Scalars['Float']['output'];
  averageQuizRedone: Scalars['Float']['output'];
  averageQuizValidated: Scalars['Float']['output'];
  monthYear: Scalars['MonthYear']['output'];
  sumCalculatedScore: Scalars['Float']['output'];
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID']['output'];
  important: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type AnswerInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  authToken?: Maybe<Scalars['String']['output']>;
  requireEmailValidation: Scalars['Boolean']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export enum Badge {
  ADAMANTIUM = 'ADAMANTIUM',
  BRONZE = 'BRONZE',
  DIAMOND = 'DIAMOND',
  EMERALD = 'EMERALD',
  GOLDEN = 'GOLDEN',
  MITHRIL = 'MITHRIL',
  OBSIDIAN = 'OBSIDIAN',
  PLATINUM = 'PLATINUM',
  QUARTZ = 'QUARTZ',
  RUBY = 'RUBY',
  SAPPHIRE = 'SAPPHIRE',
  SILVER = 'SILVER',
  TITANIUM = 'TITANIUM'
}

export type Bibliography = {
  __typename?: 'Bibliography';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type BibliographyInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  checking?: Maybe<Scalars['Boolean']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  question?: Maybe<Question>;
  user?: Maybe<User>;
};

export type CommentFilter = {
  checking?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CountWeekQuizzes = {
  __typename?: 'CountWeekQuizzes';
  count: Scalars['Int']['output'];
  week: Scalars['Int']['output'];
};

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  duration: Scalars['String']['output'];
  durationInMonth?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isAutoGenerated?: Maybe<Scalars['Boolean']['output']>;
  isValidStripe?: Maybe<Scalars['Boolean']['output']>;
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Float']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQuizInput = {
  number?: InputMaybe<Scalars['QuizNumber']['input']>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  releaseDate: Scalars['DateTime']['input'];
  themeId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateUserInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  plainPassword: Scalars['String']['input'];
  rpps?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type DailyQuestion = {
  __typename?: 'DailyQuestion';
  answer: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type DpcAccess = {
  __typename?: 'DpcAccess';
  endDate: Scalars['DateTime']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type EditQuizInput = {
  number?: InputMaybe<Scalars['QuizNumber']['input']>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  themeId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EditUserInput = {
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  address?: InputMaybe<AddressInput>;
  billingAddress?: InputMaybe<AddressInput>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  job: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  organization?: InputMaybe<Scalars['String']['input']>;
  personalPhone?: InputMaybe<Scalars['String']['input']>;
  pharmacyId?: InputMaybe<Scalars['String']['input']>;
  plainPassword?: InputMaybe<Scalars['String']['input']>;
  rpps: Scalars['String']['input'];
  type: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Essential = {
  __typename?: 'Essential';
  bibliography?: Maybe<Array<Maybe<Bibliography>>>;
  id: Scalars['ID']['output'];
  sections?: Maybe<Array<Maybe<EssentialSection>>>;
  theme?: Maybe<Theme>;
};

export type EssentialInput = {
  bibliography?: InputMaybe<Array<InputMaybe<BibliographyInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sections?: InputMaybe<Array<InputMaybe<EssentialSectionInput>>>;
};

export type EssentialSection = {
  __typename?: 'EssentialSection';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type EssentialSectionInput = {
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pdfUrl: Scalars['String']['output'];
  user: User;
};

export enum Job {
  DOCTOR = 'DOCTOR',
  MEDICAL_STUDENT = 'MEDICAL_STUDENT',
  OTHER = 'OTHER',
  PHARMACIST = 'PHARMACIST',
  PHARMACIST_ASSISTANT = 'PHARMACIST_ASSISTANT',
  PHARMACIST_HOSPITAL = 'PHARMACIST_HOSPITAL',
  PHARMACY_APPRENTICE = 'PHARMACY_APPRENTICE',
  PHARMACY_ASSISTANT = 'PHARMACY_ASSISTANT',
  PHARMACY_STUDENT = 'PHARMACY_STUDENT'
}

export type JobOption = {
  __typename?: 'JobOption';
  key: Job;
  title: Scalars['String']['output'];
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  count: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateEmail: Scalars['Boolean']['output'];
  activeUser?: Maybe<User>;
  addTeamMember?: Maybe<Subscription>;
  anonymizeUser?: Maybe<User>;
  answerQuestion?: Maybe<SessionAnswer>;
  askResetPassword?: Maybe<Scalars['Boolean']['output']>;
  authentication: Authentication;
  cancelSubscription?: Maybe<Subscription>;
  cancelUnlockQuiz: Quiz;
  createComment?: Maybe<Comment>;
  createDailyQuestion: DailyQuestion;
  createInternalSubscription: Subscription;
  createPharmacy?: Maybe<Pharmacy>;
  createPlan?: Maybe<Plan>;
  createQuiz?: Maybe<Quiz>;
  createQuizSessionLog?: Maybe<QuizSessionLog>;
  createSubscription: Subscription;
  createTheme?: Maybe<Theme>;
  createUser?: Maybe<User>;
  deleteQuiz?: Maybe<Quiz>;
  deleteRequestAccountDeletion?: Maybe<AccountDeletionRequest>;
  deleteTeamMember?: Maybe<Subscription>;
  editDpcAccess?: Maybe<DpcAccess>;
  rateQuiz?: Maybe<Rating>;
  registerPaymentMethod?: Maybe<ThreeDsUrl>;
  registration: Scalars['Boolean']['output'];
  requestAccountDeletion?: Maybe<AccountDeletionRequest>;
  resendActivationEmail: Scalars['Boolean']['output'];
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  revokeDpcAccess?: Maybe<User>;
  sendContactForm?: Maybe<Scalars['Boolean']['output']>;
  skipQuestion?: Maybe<SessionAnswer>;
  startSession?: Maybe<Session>;
  subscribe: Subscription;
  subscribeStripe: Subscription;
  subscribeTeamStripe: Subscription;
  unlockQuiz: UnlockedQuiz;
  updateComment?: Maybe<Comment>;
  updateDailyQuestion: DailyQuestion;
  updateMyPassword?: Maybe<Scalars['Boolean']['output']>;
  updateMyPharmacy?: Maybe<Pharmacy>;
  updateMyProfile?: Maybe<User>;
  updatePaymentMethod?: Maybe<ThreeDsUrl>;
  updatePharmacy?: Maybe<Pharmacy>;
  updatePlan?: Maybe<Plan>;
  updateQuiz?: Maybe<Quiz>;
  updateQuizSessionLog?: Maybe<QuizSessionLog>;
  updateSubscription: Subscription;
  updateTheme?: Maybe<Theme>;
  updateUser?: Maybe<User>;
  upgradeSubscription: Subscription;
};


export type MutationActivateEmailArgs = {
  email: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationActiveUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddTeamMemberArgs = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};


export type MutationAnonymizeUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAnswerQuestionArgs = {
  confidence: Scalars['Boolean']['input'];
  questionId: Scalars['ID']['input'];
  timeSpent?: InputMaybe<Scalars['Int']['input']>;
  userAnswersIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationAskResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationCancelUnlockQuizArgs = {
  quiz: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


export type MutationCreateCommentArgs = {
  content: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
};


export type MutationCreateDailyQuestionArgs = {
  answer: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateInternalSubscriptionArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};


export type MutationCreatePharmacyArgs = {
  pharmacy: PharmacyInput;
};


export type MutationCreatePlanArgs = {
  plan: PlanInput;
};


export type MutationCreateQuizArgs = {
  quiz: CreateQuizInput;
};


export type MutationCreateQuizSessionLogArgs = {
  quizId: Scalars['ID']['input'];
};


export type MutationCreateSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['ID']['input']>;
  plan: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  trialPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['ID']['input'];
};


export type MutationCreateThemeArgs = {
  title: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationDeleteQuizArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRequestAccountDeletionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamMemberArgs = {
  email: Scalars['String']['input'];
};


export type MutationEditDpcAccessArgs = {
  endDate: Scalars['String']['input'];
  isCompleted: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRateQuizArgs = {
  quizId: Scalars['ID']['input'];
  rating: Scalars['Int']['input'];
};


export type MutationRegisterPaymentMethodArgs = {
  address?: InputMaybe<AddressInput>;
  billingAddress?: InputMaybe<AddressInput>;
  paymentMethodId: Scalars['String']['input'];
};


export type MutationRegistrationArgs = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  job: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  organization?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  rpps?: InputMaybe<Scalars['String']['input']>;
  rppsProof?: InputMaybe<Scalars['Upload']['input']>;
  username: Scalars['String']['input'];
};


export type MutationResendActivationEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['ID']['input'];
};


export type MutationRevokeDpcAccessArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationSendContactFormArgs = {
  address: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  hasTwoReceivers?: InputMaybe<Scalars['Boolean']['input']>;
  lastname: Scalars['String']['input'];
};


export type MutationSkipQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type MutationStartSessionArgs = {
  quizId: Scalars['ID']['input'];
};


export type MutationSubscribeArgs = {
  platform: SubscriptionPlatform;
  productId?: InputMaybe<Scalars['String']['input']>;
  receipt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubscribeStripeArgs = {
  planId: Scalars['ID']['input'];
};


export type MutationSubscribeTeamStripeArgs = {
  pharmacy: PharmacyInput;
  planId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationUnlockQuizArgs = {
  quiz: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


export type MutationUpdateCommentArgs = {
  checking: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateDailyQuestionArgs = {
  answer: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateMyPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationUpdateMyPharmacyArgs = {
  pharmacy: PharmacyInput;
};


export type MutationUpdateMyProfileArgs = {
  user: UserInput;
};


export type MutationUpdatePaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};


export type MutationUpdatePharmacyArgs = {
  id: Scalars['ID']['input'];
  pharmacy: PharmacyInput;
};


export type MutationUpdatePlanArgs = {
  id: Scalars['ID']['input'];
  plan: PlanInput;
};


export type MutationUpdateQuizArgs = {
  id: Scalars['ID']['input'];
  quiz: EditQuizInput;
};


export type MutationUpdateQuizSessionLogArgs = {
  quizId: Scalars['ID']['input'];
};


export type MutationUpdateSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  urlSource?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateThemeArgs = {
  id: Scalars['ID']['input'];
  theme: ThemeInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  user: EditUserInput;
};


export type MutationUpgradeSubscriptionArgs = {
  pharmacy?: InputMaybe<PharmacyInput>;
  planId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type Operation = {
  __typename?: 'Operation';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  name: Scalars['String']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: PaymentMethodType;
};

export enum PaymentMethodType {
  CARD = 'CARD',
  SEPA = 'SEPA'
}

export type Pharmacy = {
  __typename?: 'Pharmacy';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PharmacyFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PharmacyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organization?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type Plan = {
  __typename?: 'Plan';
  amount: Scalars['Int']['output'];
  appleId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  googleId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  interval: PlanInterval;
  isTeam: Scalars['Boolean']['output'];
  metadata: PlanMetadata;
  product: Product;
  stripeId?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<Maybe<Subscription>>;
  taxRate: TaxRate;
  title: Scalars['String']['output'];
};

export type PlanDiscount = {
  __typename?: 'PlanDiscount';
  description: Array<Maybe<Scalars['String']['output']>>;
  levels?: Maybe<Array<Maybe<PlanDiscountLevel>>>;
  title: Scalars['String']['output'];
};

export type PlanDiscountLevel = {
  __typename?: 'PlanDiscountLevel';
  fixedPrice?: Maybe<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
  licencesCount: Scalars['Int']['output'];
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type PlanInput = {
  amount: Scalars['Int']['input'];
  appleId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  interval: PlanInterval;
  product: ProductInput;
  taxRate: TaxRateInput;
  title: Scalars['String']['input'];
};

export enum PlanInterval {
  month = 'month',
  year = 'year'
}

export type PlanMetadata = {
  __typename?: 'PlanMetadata';
  discount?: Maybe<PlanDiscount>;
  displayPrice: Scalars['String']['output'];
  info?: Maybe<Scalars['String']['output']>;
  infoDrawer?: Maybe<Scalars['String']['output']>;
  taxType: Scalars['String']['output'];
  threshold: Scalars['Int']['output'];
};

export type Product = {
  __typename?: 'Product';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  plans: Array<Maybe<Plan>>;
  stripeId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  _allAccountDeletionRequestsMeta?: Maybe<ListMetadata>;
  _allCommentsMeta?: Maybe<ListMetadata>;
  _allCouponsMeta?: Maybe<ListMetadata>;
  _allDailyQuestionsMeta?: Maybe<ListMetadata>;
  _allPharmaciesMeta?: Maybe<ListMetadata>;
  _allQuizzesMeta?: Maybe<ListMetadata>;
  _allRemindersMeta?: Maybe<ListMetadata>;
  _allSubscriptionsMeta?: Maybe<ListMetadata>;
  _allThemesMeta?: Maybe<ListMetadata>;
  _allUsersMeta?: Maybe<ListMetadata>;
  accountDeletionRequest?: Maybe<AccountDeletionRequest>;
  aggregatedStatistics: Array<Maybe<AggregatedStatistic>>;
  allAccountDeletionRequests: Array<Maybe<AccountDeletionRequest>>;
  allComments?: Maybe<Array<Maybe<Comment>>>;
  allCoupons?: Maybe<Array<Maybe<Coupon>>>;
  allDailyQuestions?: Maybe<Array<Maybe<DailyQuestion>>>;
  allJobs: Array<JobOption>;
  allPharmacies?: Maybe<Array<Maybe<Pharmacy>>>;
  allPlans?: Maybe<Array<Maybe<Plan>>>;
  allProducts?: Maybe<Array<Maybe<Product>>>;
  allQuizzes?: Maybe<Array<Maybe<Quiz>>>;
  allRankings?: Maybe<Array<Maybe<User>>>;
  allReminders?: Maybe<Array<Maybe<Reminder>>>;
  allSubscriptions?: Maybe<Array<Maybe<Subscription>>>;
  allTaxRates?: Maybe<Array<Maybe<TaxRate>>>;
  allThemes?: Maybe<Array<Theme>>;
  allUsers?: Maybe<Array<Maybe<User>>>;
  availablePlans?: Maybe<Array<Maybe<Plan>>>;
  comment?: Maybe<Comment>;
  coupon?: Maybe<Coupon>;
  dailyQuestion?: Maybe<DailyQuestion>;
  dailyQuiz?: Maybe<Quiz>;
  me?: Maybe<User>;
  partners?: Maybe<Array<Maybe<Partner>>>;
  pharmacy?: Maybe<Pharmacy>;
  plan?: Maybe<Plan>;
  product?: Maybe<Product>;
  quiz?: Maybe<Quiz>;
  reminder?: Maybe<Reminder>;
  subscription?: Maybe<Subscription>;
  taxRate?: Maybe<TaxRate>;
  theme?: Maybe<Theme>;
  user?: Maybe<User>;
  userMonthlyStatistic: UserMonthlyStatistic;
};


export type QueryAllCommentsMetaArgs = {
  filter?: InputMaybe<CommentFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCouponsMetaArgs = {
  filter?: InputMaybe<CouponFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPharmaciesMetaArgs = {
  filter?: InputMaybe<PharmacyFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllQuizzesMetaArgs = {
  filter?: InputMaybe<QuizFilter>;
};


export type QueryAllRemindersMetaArgs = {
  filter?: InputMaybe<ReminderFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllSubscriptionsMetaArgs = {
  filter?: InputMaybe<SubscriptionFilter>;
};


export type QueryAllThemesMetaArgs = {
  filter?: InputMaybe<ThemeFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllUsersMetaArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAccountDeletionRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAggregatedStatisticsArgs = {
  monthYears: Array<InputMaybe<Scalars['MonthYear']['input']>>;
  usersId: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryAllAccountDeletionRequestsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllCommentsArgs = {
  filter?: InputMaybe<CommentFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllCouponsArgs = {
  filter?: InputMaybe<CouponFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllDailyQuestionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllPharmaciesArgs = {
  filter?: InputMaybe<PharmacyFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllQuizzesArgs = {
  filter?: InputMaybe<QuizFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllRankingsArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRemindersArgs = {
  filter?: InputMaybe<ReminderFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllSubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllTaxRatesArgs = {
  filter?: InputMaybe<TaxRateFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllThemesArgs = {
  filter?: InputMaybe<ThemeFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCouponArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDailyQuestionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPharmacyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuizArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReminderArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaxRateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserMonthlyStatisticArgs = {
  monthYear: Scalars['MonthYear']['input'];
  userId: Scalars['ID']['input'];
};

export type Question = {
  __typename?: 'Question';
  answers: Array<Answer>;
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  quiz?: Maybe<Quiz>;
  title: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  type: QuestionType;
};

export type QuestionInput = {
  answers: Array<InputMaybe<AnswerInput>>;
  feedback: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  type: QuestionType;
};

export enum QuestionType {
  MULTI_CHOICES = 'MULTI_CHOICES',
  SINGLE_CHOICE = 'SINGLE_CHOICE'
}

export type Quiz = {
  __typename?: 'Quiz';
  createdAt: Scalars['DateTime']['output'];
  currentQuestion?: Maybe<Question>;
  histogram: Array<Maybe<Scalars['Float']['output']>>;
  id: Scalars['ID']['output'];
  initialSession?: Maybe<Session>;
  lastSession?: Maybe<Session>;
  number?: Maybe<Scalars['QuizNumber']['output']>;
  pendingSession?: Maybe<Session>;
  questions?: Maybe<Array<Maybe<Question>>>;
  questionsCount: Scalars['Int']['output'];
  rating: Scalars['Float']['output'];
  releaseDate: Scalars['DateTime']['output'];
  statistics: QuizStatistics;
  status?: Maybe<QuizStatus>;
  theme: Theme;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRating?: Maybe<Rating>;
};


export type QuizInitialSessionArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuizLastSessionArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuizPendingSessionArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuizFilter = {
  isReleased?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QuizSessionLog = {
  __typename?: 'QuizSessionLog';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  quizSession: Session;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type QuizStatistics = {
  __typename?: 'QuizStatistics';
  avgInitialSessionScore: Scalars['Float']['output'];
  avgLastSessionScore: Scalars['Float']['output'];
  avgScore: Scalars['Float']['output'];
  avgTimeSpent: Scalars['Float']['output'];
  completeSessionsCount: Scalars['Int']['output'];
  finishedInitialSessionCount: Scalars['Int']['output'];
  importantCount: Scalars['Int']['output'];
  ratingCount: Scalars['Int']['output'];
  sessionsCount: Scalars['Int']['output'];
  skippedCount: Scalars['Int']['output'];
  validCount: Scalars['Int']['output'];
};

export enum QuizStatus {
  done = 'done',
  redo = 'redo',
  todo = 'todo'
}

export enum QuizzesPerTimeScope {
  months = 'months',
  weeks = 'weeks'
}

export type Rating = {
  __typename?: 'Rating';
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  user?: Maybe<User>;
};

export type Reminder = {
  __typename?: 'Reminder';
  createdAt: Scalars['DateTime']['output'];
  days: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type ReminderFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Session = {
  __typename?: 'Session';
  answers?: Maybe<Array<Maybe<SessionAnswer>>>;
  complete: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  importantCount: Scalars['Int']['output'];
  quiz: Quiz;
  skippedCount: Scalars['Int']['output'];
  timeSpent: Scalars['Int']['output'];
  totalScore: Scalars['Float']['output'];
  user: User;
  validCount: Scalars['Int']['output'];
};

export type SessionAnswer = {
  __typename?: 'SessionAnswer';
  id: Scalars['ID']['output'];
  question: Question;
  score: Scalars['Float']['output'];
  session: Session;
  userAnswers: Array<Maybe<Answer>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  coupon?: Maybe<Coupon>;
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invitations: Array<Maybe<Operation>>;
  pastDueSince?: Maybe<Scalars['DateTime']['output']>;
  plan: Plan;
  platform: SubscriptionPlatform;
  quantity: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: SubscriptionStatus;
  stripeId?: Maybe<Scalars['String']['output']>;
  teamMembers: Array<Maybe<User>>;
  trialPeriodDays?: Maybe<Scalars['Int']['output']>;
  urlSource?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type SubscriptionFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  partner?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SubscriptionStatus>;
};

export enum SubscriptionPlatform {
  apple = 'apple',
  google = 'google',
  internal = 'internal',
  stripe = 'stripe'
}

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid'
}

export type TaxRate = {
  __typename?: 'TaxRate';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isValidStripe: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  plans: Array<Maybe<Plan>>;
  stripeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaxRateFilter = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaxRateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Theme = {
  __typename?: 'Theme';
  createdAt: Scalars['DateTime']['output'];
  essential?: Maybe<Essential>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ThemeFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ThemeInput = {
  essential?: InputMaybe<EssentialInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ThreeDsUrl = {
  __typename?: 'ThreeDSUrl';
  threeDSRedirectUrl?: Maybe<Scalars['String']['output']>;
};

export type UnlockedQuiz = {
  __typename?: 'UnlockedQuiz';
  quiz?: Maybe<Quiz>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use userMonthlyStatistic */
  badge?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Address>;
  birthdate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use userMonthlyStatistic */
  calculatedSumScore?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use address */
  city?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use address */
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  doneQuizzes: Array<Maybe<Quiz>>;
  dpcAccess?: Maybe<DpcAccess>;
  email?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  job?: Maybe<Scalars['String']['output']>;
  lastConnexion?: Maybe<Scalars['DateTime']['output']>;
  lastname: Scalars['String']['output'];
  monthlyStatistic: UserMonthlyStatistic;
  operations?: Maybe<Array<Maybe<Operation>>>;
  organization?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated Use address */
  personalAddress?: Maybe<Scalars['String']['output']>;
  personalPhone?: Maybe<Scalars['String']['output']>;
  pharmacy?: Maybe<Pharmacy>;
  redoQuizzes: Array<Maybe<Quiz>>;
  rpps?: Maybe<Scalars['String']['output']>;
  rppsProof?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<Subscription>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  switchToken: Authentication;
  todoQuizzes: Array<Maybe<Quiz>>;
  type: Scalars['String']['output'];
  unlockedQuizs?: Maybe<Array<Maybe<UnlockedQuiz>>>;
  userStats: UserStats;
  username: Scalars['String']['output'];
  /** @deprecated Use address */
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserFilter = {
  badge?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organization?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type UserInput = {
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  job?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  personalPhone?: InputMaybe<Scalars['String']['input']>;
  pharmacy?: InputMaybe<PharmacyInput>;
  plainPassword?: InputMaybe<Scalars['String']['input']>;
  rpps?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserMonthlyStatistic = {
  __typename?: 'UserMonthlyStatistic';
  badge: Badge;
  calculatedSumScore: Scalars['Float']['output'];
  lastQuizDate?: Maybe<Scalars['DateTime']['output']>;
  quizDone: Scalars['Int']['output'];
  quizRedone: Scalars['Int']['output'];
  quizValidated: Scalars['Int']['output'];
  totalQuizDone: Scalars['Int']['output'];
  totalQuizUnlocked: Scalars['Int']['output'];
};

export type UserStats = {
  __typename?: 'UserStats';
  avgScore: Scalars['Float']['output'];
  avgScoreChart: Array<Scalars['Float']['output']>;
  doneCount: Scalars['Int']['output'];
  doneQuizzesPerTime: Array<Maybe<Scalars['Int']['output']>>;
  progressionScore: Scalars['Float']['output'];
  quizzesPerTimeScope: QuizzesPerTimeScope;
  redoCount: Scalars['Int']['output'];
  redoQuizzesPerTime: Array<Maybe<Scalars['Int']['output']>>;
  sumScore: Scalars['Float']['output'];
  sumScoreChart: Array<Scalars['Float']['output']>;
  todoCount: Scalars['Int']['output'];
  user: User;
  weekQuizzes: Array<Maybe<CountWeekQuizzes>>;
};

export type LoginMutationVariables = Exact<{ [key: string]: never; }>;


export type LoginMutation = { __typename?: 'Mutation', authentication: { __typename?: 'Authentication', authToken?: string | null, requireEmailValidation: boolean } };

export type AskResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type AskResetPasswordMutation = { __typename?: 'Mutation', askResetPassword?: boolean | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['ID']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null };

export type SendContactFormMutationVariables = Exact<{
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  email: Scalars['String']['input'];
  address: Scalars['String']['input'];
  hasTwoReceivers?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SendContactFormMutation = { __typename?: 'Mutation', sendContactForm?: boolean | null };

export type SubscribeStripeMutationVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;


export type SubscribeStripeMutation = { __typename?: 'Mutation', subscribeStripe: { __typename?: 'Subscription', id: string } };

export type SubscribeTeamStripeMutationVariables = Exact<{
  planId: Scalars['ID']['input'];
  pharmacy: PharmacyInput;
  quantity: Scalars['Int']['input'];
}>;


export type SubscribeTeamStripeMutation = { __typename?: 'Mutation', subscribeTeamStripe: { __typename?: 'Subscription', id: string } };

export type RegisterPaymentMethodMutationVariables = Exact<{
  address?: InputMaybe<AddressInput>;
  billingAddress?: InputMaybe<AddressInput>;
  paymentMethodId: Scalars['String']['input'];
}>;


export type RegisterPaymentMethodMutation = { __typename?: 'Mutation', registerPaymentMethod?: { __typename?: 'ThreeDSUrl', threeDSRedirectUrl?: string | null } | null };

export type UpgradeSubscriptionStripeMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  pharmacy?: InputMaybe<PharmacyInput>;
}>;


export type UpgradeSubscriptionStripeMutation = { __typename?: 'Mutation', upgradeSubscription: { __typename?: 'Subscription', id: string } };

export type RegistrationMutationVariables = Exact<{
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  job: Scalars['String']['input'];
  email: Scalars['String']['input'];
  birthdate?: InputMaybe<Scalars['String']['input']>;
  rpps?: InputMaybe<Scalars['String']['input']>;
  rppsProof?: InputMaybe<Scalars['Upload']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  invitationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegistrationMutation = { __typename?: 'Mutation', registration: boolean };

export type AddTeamMemberMutationVariables = Exact<{
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
}>;


export type AddTeamMemberMutation = { __typename?: 'Mutation', addTeamMember?: { __typename?: 'Subscription', id: string } | null };

export type DeleteTeamMemberMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'Mutation', deleteTeamMember?: { __typename?: 'Subscription', id: string } | null };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription?: { __typename?: 'Subscription', id: string } | null };

export type UpdatePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;


export type UpdatePaymentMethodMutation = { __typename?: 'Mutation', updatePaymentMethod?: { __typename?: 'ThreeDSUrl', threeDSRedirectUrl?: string | null } | null };

export type UpdateMyPharmacyMutationVariables = Exact<{
  pharmacy: PharmacyInput;
}>;


export type UpdateMyPharmacyMutation = { __typename?: 'Mutation', updateMyPharmacy?: { __typename?: 'Pharmacy', id: string } | null };

export type UpdateMyProfileMutationVariables = Exact<{
  user: UserInput;
}>;


export type UpdateMyProfileMutation = { __typename?: 'Mutation', updateMyProfile?: { __typename?: 'User', id: string } | null };

export type UpdateMyPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdateMyPasswordMutation = { __typename?: 'Mutation', updateMyPassword?: boolean | null };

export type ActivateEmailMutationVariables = Exact<{
  url: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type ActivateEmailMutation = { __typename?: 'Mutation', activateEmail: boolean };

export type ResendActivationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendActivationEmailMutation = { __typename?: 'Mutation', resendActivationEmail: boolean };

export type CouponQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CouponQuery = { __typename?: 'Query', coupon?: { __typename?: 'Coupon', id: string, percentOff?: number | null, amountOff?: number | null } | null };

export type DailyQuestionQueryVariables = Exact<{ [key: string]: never; }>;


export type DailyQuestionQuery = { __typename?: 'Query', dailyQuestion?: { __typename?: 'DailyQuestion', id: string, title: string, answer: string } | null };

export type AllJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllJobsQuery = { __typename?: 'Query', allJobs: Array<{ __typename?: 'JobOption', key: Job, title: string }> };

export type AvailablePlansQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailablePlansQuery = { __typename?: 'Query', availablePlans?: Array<{ __typename?: 'Plan', id: string, title: string, description?: string | null, amount: number, interval: PlanInterval, metadata: { __typename?: 'PlanMetadata', taxType: string, threshold: number, displayPrice: string, info?: string | null, infoDrawer?: string | null, discount?: { __typename?: 'PlanDiscount', title: string, description: Array<string | null>, levels?: Array<{ __typename?: 'PlanDiscountLevel', label: string, licencesCount: number, percentOff?: number | null, fixedPrice?: number | null } | null> | null } | null } } | null> | null };

export type ScoresQueryVariables = Exact<{
  usersId: Array<InputMaybe<Scalars['ID']['input']>>;
  monthYears: Array<InputMaybe<Scalars['MonthYear']['input']>>;
}>;


export type ScoresQuery = { __typename?: 'Query', aggregatedStatistics: Array<{ __typename?: 'AggregatedStatistic', monthYear: string, sumCalculatedScore: number, averageQuizDone: number, averageQuizValidated: number, averageQuizRedone: number } | null> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, avatar?: string | null, firstname: string, lastname: string, username: string, birthdate?: string | null, email?: string | null, job?: string | null, rpps?: string | null, dpcAccess?: { __typename?: 'DpcAccess', startDate: string, endDate: string, isCompleted: boolean } | null, address?: { __typename?: 'Address', address?: string | null, address2?: string | null, city?: string | null, zipCode?: string | null, country?: string | null } | null, pharmacy?: { __typename?: 'Pharmacy', id: string, name?: string | null, address?: string | null, zipCode?: string | null, city?: string | null, country?: string | null, organization?: string | null } | null, monthlyStatistic: { __typename?: 'UserMonthlyStatistic', badge: Badge, lastQuizDate?: string | null, totalQuizDone: number, totalQuizUnlocked: number } } | null };

export type MyPharmacyQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPharmacyQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, pharmacy?: { __typename?: 'Pharmacy', id: string, name?: string | null, address?: string | null, zipCode?: string | null, city?: string | null, country?: string | null, organization?: string | null } | null } | null };

export type MySubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type MySubscriptionQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, subscription?: { __typename?: 'Subscription', id: string, platform: SubscriptionPlatform, quantity: number, status: SubscriptionStatus, startDate?: string | null, trialPeriodDays?: number | null, user?: { __typename?: 'User', id: string } | null, teamMembers: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, job?: string | null, monthlyStatistic: { __typename?: 'UserMonthlyStatistic', badge: Badge, lastQuizDate?: string | null, totalQuizDone: number, totalQuizUnlocked: number } } | null>, plan: { __typename?: 'Plan', id: string, amount: number, title: string, interval: PlanInterval, isTeam: boolean, metadata: { __typename?: 'PlanMetadata', taxType: string, threshold: number, displayPrice: string, info?: string | null, infoDrawer?: string | null, discount?: { __typename?: 'PlanDiscount', title: string, description: Array<string | null>, levels?: Array<{ __typename?: 'PlanDiscountLevel', label: string, licencesCount: number, percentOff?: number | null, fixedPrice?: number | null } | null> | null } | null } } } | null } | null };

export type MyLicencesStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type MyLicencesStatusQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null, subscription?: { __typename?: 'Subscription', id: string, quantity: number, teamMembers: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email?: string | null } | null>, invitations: Array<{ __typename?: 'Operation', firstname: string, lastname: string, email: string } | null> } | null } | null };

export type MyPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPaymentMethodQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, paymentMethod?: { __typename?: 'PaymentMethod', name?: string | null, type: PaymentMethodType, email?: string | null, country?: string | null, last4?: string | null } | null } | null };

export type MyInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyInvoicesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, invoices?: Array<{ __typename?: 'Invoice', createdAt: string, amount: number, pdfUrl: string } | null> | null } | null };

export type MyBillingAddressQueryVariables = Exact<{ [key: string]: never; }>;


export type MyBillingAddressQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, pharmacy?: { __typename?: 'Pharmacy', name?: string | null } | null, billingAddress?: { __typename?: 'Address', address?: string | null, address2?: string | null, zipCode?: string | null, city?: string | null, country?: string | null } | null } | null };
